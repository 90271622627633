var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 col-md-6 border-sm-right"},[_c('div',{ref:"mainView"},[_c('insurance-item',{attrs:{"title":_vm.insurance.title,"single":true,"imageUrl":_vm.businessTypes[_vm.insurance.businessType]
            ? _vm.businessTypes[_vm.insurance.businessType].url : '#',"color":_vm.businessTypes[_vm.insurance.businessType]
            ? _vm.businessTypes[_vm.insurance.businessType].color : '#fff',"businessType":_vm.insurance.businessType,"type":_vm.insurance.type,"description":_vm.insurance.description,"showPayout":['active', 'finished', 'claimed'].includes(_vm.insurance.statusType),"price":_vm.insurance.price.toString(),"payout":_vm.insurance.payout.toString(),"status":_vm.insurance.status,"currency":_vm.insurance.currency,"claim_payout_btn":_vm.str.insurance.claim_payout_btn,"potential_payout":_vm.str.insurance.potential_payout,"price_including_vat":_vm.str.commonApp.price_including_vat,"weather_type":_vm.str.commonApp.weather_type ?
            _vm.str.commonApp.weather_type[_vm.insurance.type] : '',"business-type-string":_vm.str.commonApp.business_type ?
          _vm.str.commonApp.business_type[_vm.insurance.businessType] : ''},on:{"claim":_vm.sendClaimClick}}),(_vm.showClaimForm)?_c('claims-form',{ref:"claimForm",attrs:{"initial-birthday":_vm.initialBirthday,"first-name-initial":_vm.initialFirstname,"last-name-initial":_vm.initialLastname,"first-name-place-holder":_vm.str.insurance.first_name_plh,"last-name-place-holder":_vm.str.insurance.last_name_plh,"additional-firstnames-place-holder":_vm.str.insurance.additional_first_names_plh,"birth-place-place-holder":_vm.str.insurance.birth_place_plh,"payout-iban-place-holder":_vm.str.insurance.payout_iban_plh,"birthday-label":_vm.str.insurance.birth_day_plh,"error-payout-iban":_vm.str.insurance.error_payout_iban,"claim-text":_vm.str.insurance.claim_text,"error-birth-place":_vm.str.insurance.error_birth_place,"error-first-name":_vm.str.commonApp.error_firstname,"error-last-name":_vm.str.commonApp.error_lastname,"lang":_vm.lang},on:{"change-firstname":(v) => this.firstname = v,"change-lastname":(v) => this.lastname = v,"change-additional-firstnames":(v) => this.additionalFirstnames = v,"change-birth-place":(v) => this.birthPlace = v,"change-birthday":(v) => this.birthday = v,"change-payout-iban":(v) => this.payoutIban = v.toUpperCase()}}):_vm._e(),_c('div',{staticClass:"border-y-between"},[(!['3', '8'].includes((_vm.order.status || '').toString())
          && _vm.showPolicy)?_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"empty-ph font-weight-bold mb-3"},[_vm._v(" "+_vm._s(_vm.str.insurance && _vm.str.insurance.download_title)+" ")]),_c('p',{staticClass:"empty-ph"},[_vm._v(" "+_vm._s(_vm.str.insurance && _vm.str.insurance.download_subtitle)+" ")]),_c('download-policy',{attrs:{"url":_vm.apiPolicy,"errorMessage":_vm.str.insurance && _vm.str.insurance.error_policy_dwnld,"lazy":true}},[_vm._v(_vm._s(_vm.str.insurance && _vm.str.insurance.download_btn))])],1):_vm._e(),_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"font-weight-bold mb-3"},[_vm._v(_vm._s(_vm.str.buy && _vm.str.buy.offer))]),(_vm.order.insurer)?[_c('div',{staticClass:"empty-ph wet-note"},[_vm._v(" "+_vm._s(_vm.str.buy && _vm.str.buy.insurer_name)+" ")]),_c('p',{staticClass:"empty-ph"},[_vm._v(_vm._s(_vm.order.insurer && _vm.order.insurer.name))])]:_vm._e(),_c('div',{staticClass:"empty-ph wet-note"},[_vm._v(" "+_vm._s(_vm.str.insurance && _vm.str.insurance.insured_location)+" ")]),(_vm.rloc.name)?_c('p',{staticClass:"empty-ph mb-0"},[_vm._v(_vm._s(_vm.rloc.name))]):_vm._e(),_c('p',{staticClass:"empty-ph mb-0"},[_vm._v(_vm._s(_vm.rloc.address))]),_c('p',{staticClass:"empty-ph"},[_vm._v(_vm._s(_vm.coordinates))])],2),_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"font-weight-bold mb-3"},[_vm._v(" "+_vm._s(_vm.str.buy && _vm.str.buy.insured_weather)+" ")]),_c('div',{staticClass:"d-flex align-items-center mb-3"},[_c('weather-icon',{staticClass:"wet-icon-large text-secondary mr-3",style:(_vm.baloiseStrings ? 'width:2rem;' : ''),attrs:{"type":_vm.insurance.type}}),_c('div',[_c('p',{staticClass:"empty-ph"},[_vm._v(_vm._s(_vm.insured_weather))])])],1),_c('div',{staticClass:"empty-ph wet-note"},[_vm._v(_vm._s(_vm.str.buy && _vm.str.buy.period))]),_c('p',{staticClass:"empty-ph mb-3"},[_vm._v(_vm._s(_vm.period))]),(_vm.timesAdjacent && _vm.order.weather
                && _vm.order.weather.direction === _vm.availableWeatherDirs.rainy)?_c('p',{staticClass:"empty-ph mt-n3"},[_vm._v(_vm._s(_vm.timesAdjacent))]):_vm._e(),_c('div',{staticClass:"empty-ph wet-note"},[_vm._v(" "+_vm._s(_vm.str.buy && _vm.str.buy.deductible_days)+" ")]),_c('p',{staticClass:"empty-ph-small"},[_vm._v(_vm._s(_vm.order.risk_days))]),_c('div',{staticClass:"empty-ph wet-note"},[_vm._v(" "+_vm._s(_vm.str.buy && _vm.str.buy.payout_per_day)+" ")]),_c('p',[_c('money-field',{attrs:{"value":_vm.order.daily_payout,"dynamic-cents":true,"currency":_vm.insurance.currency}})],1),_c('div',{staticClass:"empty-ph wet-note"},[_vm._v(" "+_vm._s(_vm.str.buy && _vm.str.buy.maximum_payout)+" ")]),_c('p',[_c('money-field',{attrs:{"value":_vm.order.reduction,"dynamic-cents":true,"currency":_vm.insurance.currency}})],1)]),_c('div',{staticClass:"p-3"},[_c('div',{staticClass:"font-weight-bold mb-3"},[_vm._v(" "+_vm._s(_vm.str.insurance && _vm.str.insurance.potential_payout)+" ")]),_c('div',{staticClass:"d-flex align-items-center"},[(!_vm.baloiseStrings)?_c('wet-icon',{staticClass:"wet-icon-large text-secondary mr-3",attrs:{"type":"success"}}):_vm._e(),_c('div',[_c('money-field',{attrs:{"value":_vm.insurance.payout,"size":"lg","currency":_vm.insurance.currency}})],1)],1)]),_c('div',{staticClass:"wet-aspect-half"},[_c('location-component',{attrs:{"place":_vm.order.location || {}}}),_c('div',{staticClass:"wet-map-label shadow px-3 px-sm-4 py-1 py-sm-2"},[_vm._v(" "+_vm._s(_vm.str.buy && _vm.str.buy.business_address)+" ")])],1)])],1)]),_c('div',{staticClass:"col"},[_c('insurance-details',{attrs:{"items":_vm.claims,"currency":_vm.insurance.currency}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }