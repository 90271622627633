<template>
  <div class="d-flex flex-column">
    <div class="row no-gutters">
      <div class="col-12 col-md-6 border-sm-right">
        <div ref="mainView">
          <insurance-item
            :title="insurance.title"
            :single="true"
            :imageUrl="businessTypes[insurance.businessType]
              ? businessTypes[insurance.businessType].url : '#'"
            :color="businessTypes[insurance.businessType]
              ? businessTypes[insurance.businessType].color : '#fff'"
            :businessType="insurance.businessType"
            :type="insurance.type"
            :description="insurance.description"
            :showPayout="['active', 'finished', 'claimed'].includes(insurance.statusType)"
            :price="insurance.price.toString()"
            :payout="insurance.payout.toString()"
            :status="insurance.status"
            :currency="insurance.currency"
            :claim_payout_btn="str.insurance.claim_payout_btn"
            :potential_payout="str.insurance.potential_payout"
            :price_including_vat="str.commonApp.price_including_vat"
            :weather_type="str.commonApp.weather_type ?
              str.commonApp.weather_type[insurance.type] : ''"
            :business-type-string="str.commonApp.business_type ?
            str.commonApp.business_type[insurance.businessType] : ''"
            @claim="sendClaimClick"
          ></insurance-item>
          <claims-form v-if="showClaimForm"
            ref="claimForm"
            :initial-birthday="initialBirthday"
            :first-name-initial="initialFirstname"
            :last-name-initial="initialLastname"
            :first-name-place-holder="str.insurance.first_name_plh"
            :last-name-place-holder="str.insurance.last_name_plh"
            :additional-firstnames-place-holder="str.insurance.additional_first_names_plh"
            :birth-place-place-holder="str.insurance.birth_place_plh"
            :payout-iban-place-holder="str.insurance.payout_iban_plh"
            :birthday-label="str.insurance.birth_day_plh"
            :error-payout-iban="str.insurance.error_payout_iban"
            :claim-text="str.insurance.claim_text"
            :error-birth-place="str.insurance.error_birth_place"
            :error-first-name="str.commonApp.error_firstname"
            :error-last-name="str.commonApp.error_lastname"
            :lang="lang"
            @change-firstname="(v) => this.firstname = v"
            @change-lastname="(v) => this.lastname = v"
            @change-additional-firstnames="(v) => this.additionalFirstnames = v"
            @change-birth-place="(v) => this.birthPlace = v"
            @change-birthday="(v) => this.birthday = v"
            @change-payout-iban="(v) => this.payoutIban = v.toUpperCase()"
          />
          <div class="border-y-between">
            <div v-if="!['3', '8'].includes((order.status || '').toString())
            && showPolicy" class="p-3">
              <!-- download section -->
              <div class="empty-ph font-weight-bold mb-3">
                {{ str.insurance && str.insurance.download_title }}
              </div>
              <p class="empty-ph">
                {{ str.insurance && str.insurance.download_subtitle }}
              </p>
              <download-policy
                :url="apiPolicy"
                :errorMessage="str.insurance && str.insurance.error_policy_dwnld"
                :lazy="true"
              >{{ str.insurance && str.insurance.download_btn }}</download-policy>
            </div>
            <div class="p-3">
              <!-- your offer -->
              <div class="font-weight-bold mb-3">{{ str.buy && str.buy.offer }}</div>
              <template v-if="order.insurer">
                <div class="empty-ph wet-note ">
                  {{ str.buy && str.buy.insurer_name }}
                </div>
                <p class="empty-ph">{{ order.insurer && order.insurer.name }}</p>
              </template>
              <div class="empty-ph wet-note ">
                {{ str.insurance && str.insurance.insured_location }}
              </div>
              <p v-if="rloc.name" class="empty-ph mb-0">{{ rloc.name }}</p>
              <p class="empty-ph mb-0">{{ rloc.address }}</p>
              <p class="empty-ph">{{ coordinates }}</p>
            </div>
            <div class="p-3">
              <!-- insured_weather -->
              <div class="font-weight-bold mb-3">
                {{ str.buy && str.buy.insured_weather }}
              </div>
              <div class="d-flex align-items-center mb-3">
                <weather-icon
                  class="wet-icon-large text-secondary mr-3"
                  :type="insurance.type"
                  :style="baloiseStrings ? 'width:2rem;' : ''"
                ></weather-icon>
                <div>
                  <p class="empty-ph">{{ insured_weather }}</p>
                </div>
              </div>
              <div class="empty-ph wet-note ">{{ str.buy && str.buy.period }}</div>
              <p class="empty-ph mb-3">{{ period }}</p>
              <p
                v-if="timesAdjacent && order.weather
                  && order.weather.direction === availableWeatherDirs.rainy"
                class="empty-ph mt-n3"
              >{{ timesAdjacent }}</p>
              <div class="empty-ph wet-note ">
                {{ str.buy && str.buy.deductible_days }}
              </div>
              <p class="empty-ph-small">{{ order.risk_days }}</p>
              <div class="empty-ph wet-note ">
                {{ str.buy && str.buy.payout_per_day }}
              </div>
              <p>
                <money-field
                  :value="order.daily_payout"
                  :dynamic-cents="true"
                  :currency="insurance.currency"
                />
              </p>
              <div class="empty-ph wet-note ">
                {{ str.buy && str.buy.maximum_payout }}
              </div>
              <p>
                <money-field
                  :value="order.reduction"
                  :dynamic-cents="true"
                  :currency="insurance.currency"
                />
              </p>
            </div>
            <div class="p-3">
              <div class="font-weight-bold mb-3">
                {{str.insurance && str.insurance.potential_payout}}
              </div>
              <div class="d-flex align-items-center">
                <wet-icon v-if="!baloiseStrings"
                  class="wet-icon-large text-secondary mr-3"
                  type="success"
                ></wet-icon>
                <div>
                  <money-field
                    :value="insurance.payout"
                    size="lg"
                    :currency="insurance.currency"
                  />
                </div>
              </div>
            </div>
            <div class="wet-aspect-half">
              <location-component
                :place="order.location || {}"
              ></location-component>
              <div class="wet-map-label shadow px-3 px-sm-4 py-1 py-sm-2">
                {{ str.buy && str.buy.business_address }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <insurance-details
          :items="claims"
          :currency="insurance.currency"
        />
      </div>
    </div>
  </div>
</template>

<script>
import insuranceItem from '@/components/insurance-item.vue';
import insuranceDetails from '@/components/insurance-details.vue';
import insuranceHelper from '@/mixins/insurance';
import changeLang from '@/mixins/changeLang';
import { mapState } from 'vuex';
import places from '@/mixins/places';
import locationComponent from '@/components/locationComponent.vue';
import insuranceId from '@/mixins/insuranceId';
import claimsForm from '@/components/insuranceId/ClaimsForm.vue';
import configEnv from '@/enums/configEnv';

export default {
  mixins: [insuranceHelper, places, changeLang, insuranceId],
  name: 'insuranceIdVacation',
  components: {
    claimsForm,
    insuranceItem,
    weatherIcon: () => import(/* webpackChunkName: "weatherIcon" */ '@/components/weatherIcon.vue'),
    wetIcon: () => import(/* webpackChunkName: "wetIcon" */ '@/components/wetIcon.vue'),
    locationComponent,
    insuranceDetails,
    downloadPolicy: () => import(/* webpackChunkName: "downloadPolicy" */ '@/components/downloadPolicy.vue'),
    moneyField: () => import(/* webpackChunkName: "moneyField" */ '@/components/ui/moneyFieldUi.vue'),
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState(['strings', 'loading']),
    rloc() {
      return this.order.location || {};
    },
  },
  data() {
    return {
      baloiseStrings: configEnv.baloise_strings,
      showPolicy: configEnv.show_policy,
    };
  },
  async created() {
    await this.init();
  },
  destroyed() {
    // unwatch next & back buttons click watcher
    this.$root.$off('buttonBack', this.back);
  },
};
</script>
